import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const styles = {
  container: {
    backgroundColor: "#fff", // Set the background color
    color: "#012b41", // Set the text color
  },
  headerBox: {
    textAlign: "center",
    padding: "2rem 4rem",
    backgroundColor: "#e5eeeb", // Replace with the actual color from your brand palette
    borderRadius: "4px",
    margin: "0 auto", // Adjust margin to suit your layout
  },
  featureGrid: {
    marginBottom: "2rem",
  },
  featureTitle: {
    color: "#012b41", // This should match the color in the screenshot
    fontWeight: "bold",
    margin: "1rem 0",
  },
  featureItem: {
    height: "70%",
    padding: "1rem",
    border: "1px solid #012b41", // Adjust border color as needed
    borderRadius: "4px",
    margin: "0.5rem 0", // Spacing between feature items
    marginBottom: "3em",
  },
  getStartedButton: {
    color: "#012b41",
    marginTop: "1rem",
    backgroundColor: "#f3bb96", // Use your primary button color
    "&:hover": {
      backgroundColor: "#bf8d71", // Darken on hover
    },
  },
};

const Feature = ({ title, points }) => (
  <Grid item xs={12} sm={6} md={3}>
    <Typography
      variant="h6"
      component="h3"
      gutterBottom
      sx={styles.featureTitle}
    >
      {title}
    </Typography>
    <Box sx={styles.featureItem}>
      <List>
        {points.map((point, index) => (
          <ListItem key={index}>
            <ListItemText primary={point} />
          </ListItem>
        ))}
      </List>
    </Box>
  </Grid>
);

const features = [
  {
    title: "Member Management",
    points: [
      "One-click member sign-ups",
      "Easy-to-use member directories",
      "Customizable membership tiers",
    ],
  },
  {
    title: "Pass and Ticketing",
    points: [
      "Quick pass issuance",
      "Real-time ticket availability",
      "Secure payment gateways",
    ],
  },
  {
    title: "Event Planning",
    points: [
      "Event calendar integration",
      "RSVP and guest list management",
      "Automated event reminders",
    ],
  },
  {
    title: "Order Management",
    points: [
      "Streamlined order processing",
      "Inventory tracking",
      "Automated billing and invoicing",
    ],
  },
  {
    title: "Reporting and Analytics",
    points: [
      "Real-time performance metrics",
      "Financial reporting",
      "Member engagement analytics",
    ],
  },
  {
    title: "Mobile Accessibility",
    points: [
      "Fully responsive design",
      "Native mobile apps",
      "Real-time notifications",
    ],
  },
  {
    title: "Social Media Integration",
    points: [
      "Automated posting to club’s social media profiles",
      "Member check-ins and event sharing to social networks",
      "Social media analytics for measuring engagement",
      "Targeted social media advertising tools",
    ],
  },
  {
    title: "Member Marketing",
    points: [
      "Email marketing campaigns with customizable templates",
      "SMS alerts for promotions and club updates",
      "Referral programs to incentivize member invites",
      "Loyalty and rewards system for frequent visitors",
    ],
  },
  {
    title: "Interactive Member Portal",
    points: [
      "Personalized member profiles with social media linking",
      "Interactive event calendars and RSVP features",
      "Member-to-member messaging and networking",
      "Feedback and survey tools for service improvement",
    ],
  },
  {
    title: "Promotions Management",
    points: [
      "Time-limited offers and coupon generation",
      "Seasonal promotion scheduling",
      "Real-time promotion performance tracking",
      "Dynamic pricing based on demand forecasting",
    ],
  },
];

const Landing = () => {
  return (
    <Box sx={styles.headerBox}>
      <Container maxWidth="lg">
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
          <Box my={5} textAlign={"left"}>
            <Typography variant="h3" component="h1" gutterBottom>
              Simplify Club Management, Amplify Member Engagement
            </Typography>
            <Typography variant="h6" color="text.secondary" paragraph>
              Your All-in-One SaaS Solution for Running a Thriving Private Club
            </Typography>
            <Button
              sx={styles.getStartedButton}
              variant="contained"
              size="large"
            >
              GET STARTED NOW!
            </Button>
          </Box>
          <Box
            component="img"
            src="/images/myc.jpg"
            sx={{
              width: "100%",
              maxWidth: "500px",
              height: "auto",
              objectFit: "cover",
              borderRadius: "4px",
            }}
          />
        </Box>

        {/* Features Section */}
        <Grid container spacing={2} sx={styles.featureGrid}>
          {features.map((feature, index) => (
            <Feature
              key={index}
              title={feature.title}
              points={feature.points}
            />
          ))}
        </Grid>

        {/* You could add more sections below such as Testimonials, Pricing, etc. */}
      </Container>
    </Box>
  );
};

export default Landing;
