/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext, useEffect, useState, useRef } from "react";
import { storage } from "../services/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AppContext } from "../context/AppContext";
import {
  Avatar,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  CardActions,
  FormControlLabel,
  Switch,
  Snackbar,
} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/ReceiptLong";
import { MemberPasses } from "../components/MemberPasses";
import { BudPass } from "../components/BudPass";
import {
  getMemberOrders,
  updateProfile,
  centsToCurrency,
} from "../services/firebase";
import { Link, useLocation } from "react-router-dom";
import {
  enforceFormat,
  formatToPhone,
  timeToDateString,
} from "../services/utils";

export const Profile = () => {
  const { member, memberPasses } = useContext(AppContext);
  const [imagePreview, setImagePreview] = useState(null); // State for storing the image preview URL
  const location = useLocation();

  const [displayName, setDisplayName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailOptIn, setEmailOptIn] = useState(false);
  const [orders, setOrders] = useState([]);
  const [profileUpdated, setProfileUpdated] = useState(false);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleClose = () => {
    setProfileUpdated(false);
  };

  useEffect(() => {
    if (!member) {
      return;
    }
    getMemberOrders(member.id).then((orders) => {
      setOrders(orders);
    });
  }, [member]);

  useEffect(() => {
    if (!member) {
      return;
    }
    setDisplayName(member.displayName);
    setPhoneNumber(member.phoneNumber);
    setEmailOptIn(member.emailOptIn);
  }, [member]);

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  if (!member) {
    return null;
  }

  const isAnnualPassHolder = memberPasses.find(
    (pass) => pass.status === "active" && pass.type === "year"
  );

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImagePreview(URL.createObjectURL(img));
      const imageRef = ref(storage, `members/${member.id}`);
      await uploadBytes(imageRef, img);
      const imageUrl = await getDownloadURL(imageRef);
      if (imageUrl) {
        updateProfile(member, { photoURL: imageUrl });
        setProfileUpdated(true);
      }
    }
  };

  const onUpdateProfile = async () => {
    updateProfile(member, { displayName, phoneNumber, emailOptIn });
    setProfileUpdated(true);
  };

  return (
    <Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key="bottomcenter"
        onClose={handleClose}
        open={profileUpdated}
        message="Profile Updated!"
        variant="success"
        autoHideDuration={5000}
      />
      <Card sx={{ my: 2, p: 2 }}>
        <CardHeader title="Update Your Profile" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar
                sizes="large"
                sx={{
                  width: "20vw",
                  maxWidth: "100px",
                  height: "20vw",
                  maxHeight: "100px",
                }}
                alt={member.displayName}
                src={imagePreview || member.photoURL}
                onClick={handleButtonClick}
              />
              <input
                id="imageInput"
                type="file"
                accept="/image/*"
                ref={fileInputRef}
                onChange={onImageChange}
                style={{ display: "none" }}
              />
              <Button
                size="small"
                fullWidth
                variant="text"
                color="primary"
                sx={{ mt: 1 }}
                onClick={handleButtonClick}
              >
                Upload
              </Button>
            </Grid>
            <Grid item xs={9}>
              <Box sx={{ mb: 1 }}>
                <TextField
                  label="Name"
                  value={displayName}
                  fullWidth
                  onChange={(event) => {
                    setDisplayName(event.target.value);
                  }}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <TextField
                  label="Phone"
                  value={phoneNumber}
                  fullWidth
                  onKeyUp={formatToPhone}
                  onKeyDown={enforceFormat}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                  }}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ mr: 1 }}
                      checked={emailOptIn}
                      onChange={(event) => setEmailOptIn(event.target.checked)}
                    />
                  }
                  label="Send me email about events and promotions"
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Box textAlign={"right"} flexGrow={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={onUpdateProfile}
            >
              Update
            </Button>
          </Box>
        </CardActions>
      </Card>
      <Card sx={{ my: 2, p: 2 }}>
        <CardHeader title="Membership" />
        <CardContent>
          <Typography variant="body1">
            <strong>Email: </strong> {member.email}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Joined: </strong> {timeToDateString(member.createdate)}
          </Typography>
          {isAnnualPassHolder ? <BudPass /> : null}
          <a id="membership" title="membership" />
          <MemberPasses />
        </CardContent>
      </Card>
      <Card sx={{ my: 2, p: 2 }}>
        <CardHeader title="Orders" />
        <CardContent>
          {orders.length === 0 ? (
            <Typography variant="body1">You have no orders.</Typography>
          ) : null}
          {orders.map((order) => (
            <Box
              key={order.id}
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #ccc",
                pb: 1,
              }}
            >
              <Typography variant="body1">
                {timeToDateString(order.createdate)}
              </Typography>
              <Typography variant="body1">
                {centsToCurrency(order.amount)}
              </Typography>
              <Link to={`/receipt/${order.id}`}>
                <ReceiptIcon />
              </Link>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Container>
  );
};
