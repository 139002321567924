import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { sharePassesWithLink } from "../services/firebase";

import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import { AppContext } from "../context/AppContext";

export const Share = () => {
  const { memberPasses } = useContext(AppContext);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [error, setError] = useState(null);
  const [shareLink, setShareLink] = useState(null);

  const apiRef = useGridApiRef();

  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  const copyToClipboard = () => {
    if (shareLink) {
      navigator.clipboard.writeText(shareLink);
    }
  };

  const shareablePasses = memberPasses.filter((pass) => {
    return pass.status === "pending";
  });

  const sharePasses = () => {
    setError(null);
    const selectedPasses = apiRef.current.getSelectedRows();

    const message = document.getElementById("message").value;

    if (selectedPasses.length === 0) {
      setError("Please select at least one pass to share.");
      return;
    }
    const sharedPasses = [...selectedPasses].reduce((acc, row) => {
      return [...acc, row[0]];
    }, []);
    sharePassesWithLink(sharedPasses, message)
      .then(({ data }) => {
        const shareLink = `${window.location.origin}/claim/${data.linkId}`;
        setError(null);
        setShareLink(shareLink);
        setOpenConfirmation(true);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <>
      <Dialog open={openConfirmation} onClose={closeConfirmation}>
        <DialogTitle>Share Passes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The following link will allow your friends to claim the passes
            you've selected.
          </DialogContentText>
          <Input
            id="share-link"
            fullWidth
            sx={{ fontSize: "1.5em", mt: 2, p: 1 }}
            value={shareLink}
            onClick={copyToClipboard}
            variant="outlined"
            readOnly
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={copyToClipboard}>
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            }
          />
        </DialogContent>
      </Dialog>
      <Paper elevation={12} sx={{ mt: 4, p: 4, minHeight: "80vh" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Share Passes with Friends
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can share passes and tickets with friends by sending them a link!
          First, select the passes or tickets you want to share from the list
          below.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Then, click the "Share" button to generate a link that you can send to
          your friends. Anyone with the link will be able to claim the passes or
          tickets you've shared.
        </Typography>
        {error && (
          <Typography variant="body1" color="error" gutterBottom>
            {error}
          </Typography>
        )}
        <Typography variant="h6" component="h2" gutterBottom sx={{ mt: 4 }}>
          Write a message to your friends
        </Typography>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <TextField
            id="message"
            defaultValue="Hey, I have some tickets for you! Let's get together and have some fun!"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <DataGrid
          rows={shareablePasses}
          apiRef={apiRef}
          columns={[
            { field: "name", headerName: "Select passes below", width: "300" },
            { field: "createdate", headerName: "Date Purchased", width: "200" },
            { field: "dateFirstUsed", headerName: "Event Date", width: "200" },
          ]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          checkboxSelection
          disableSelectionOnClick
        />

        <Box sx={{ mt: 4, mb: 4 }}>
          <Button
            onClick={sharePasses}
            variant="contained"
            color="primary"
            sx={{ mr: 2 }}
          >
            Share
          </Button>
        </Box>
      </Paper>
    </>
  );
};
